import React from "react"
import { Link, graphql } from "gatsby"

// import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    // const posts = data.allMarkdownRemark.edges
    // const posts = data.articles.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Células" />
        {/* <Bio /> */}
        {data.articles.edges.map( article => {
          const title = article.node.data.title.text || article.node.slugs[0]
          const excerpt = article.node.data.paragraph.text.substring(0, 200) + '…'
          
          console.log(article)
          return (
            <article key={article.node.slugs[0]}>
              <header>
                <h3
                  style={{
                    marginBottom: rhythm(1 / 4),
                  }}
                >
                  <Link style={{ boxShadow: `none` }} to={article.node.slugs[0]}>
                    {title}
                  </Link>
                </h3>
                <small>{article.node.data.date}</small>
              </header>
              <section>
                <p
                  dangerouslySetInnerHTML={{
                    __html: excerpt
                  }}
                />
              </section>
            </article>
          )
        })}
      </Layout>
    )
  }
}

export default BlogIndex

export const IndexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    articles: allPrismicArticle (
      sort: {
        fields: data___date,
        order: DESC
      }
    ) {
      edges {
        node {
          slugs
          data {
            date
            title {
              text
            }
            image {
              url
              alt
            }
            paragraph {
              html,
              text
            }
          }
        }
      }
    }
  }
`

// export const pageQuery = graphql`
//   query {
//     site {
//       siteMetadata {
//         title
//       }
//     }
//     allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
//       edges {
//         node {
//           excerpt
//           fields {
//             slug
//           }
//           frontmatter {
//             date(formatString: "MMMM DD, YYYY")
//             title
//             description
//           }
//         }
//       }
//     }
//   }
// `
